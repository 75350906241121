<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4'>
       <b-col>
        <b-form @submit='onSubmit'>
          <router-link class="btn btn-sm btn-dark  "  size="sm" :to="{ path: '/brands/displayPremiums/'+this.id}">
            <em class="fa fa-backward btn-sm btn-dark "></em> Back
          </router-link>
          <div class='card'>
            <div class='bg-primary p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Create Premiums Brands({{this.name}})</h5>
            </div>
            <div class='card-body'>
              <div class="col-md-4">
                <b-row>
                  <b-col>
                  <label>Premium Offers</label>
                  
                    <multiselect
                      placeholder="Enter Premiums Offer"
                      open-direction="bottom"
                      v-model="form.default_premium"
                      label="name"
                      track-by="name"
                      :options="premiumSearchOptions"
                      :loading="isPremiumSearch"
                      :searchable="true"
                      :close-on-select="true"
                      :options-limit="10"
                      :multiple="true"
                      @search-change="premiumFind">
                    </multiselect>
                  </b-col>
                </b-row>
              <b-row>
              <label></label>
              <div class="col-md-4">
                <br/>
                <b-button variant='primary' class="btn btn-sm  col-sm-auto"
                   type='submit'>Create Premiums
                </b-button>
              </div>
            </b-row>
          </div>  
        </div>
      </div>
    </b-form>
  </b-col>
  </b-row>
  </b-overlay>
</template>

<script>
import {mapState,mapActions } from 'vuex';
import { CreatePremiumPath } from '../../router/clients';
export default {
  name: 'CreatePremiumPath',
  data() {
    return {
      CreatePremiumPath,
      currentPage: 1,
      debounce: null,
      id: null,
      form: {
        default_premium:[],
      },
      isPremiumSearch: false,
      premiumSearchOptions: [],
    };
  }, computed: {
    ...mapState({
      isLoading : (state) => state.brands.isLoading,
      isSuccess : (state) => state.brands.isSuccess,
      message: (state) => state.brands.successMessage,
      name: (state) => state.brands.name,
    }),
  },created() {
    this.id = this.$route.params.id;
    this.setPremiumLimit();
    this.getBrandsNameById(this.id);
  },
  watch: {
    message: function() {
      if (!this.message) return;
      if(!this.isSuccess) return;
      if (!this.isSuccess){
        this.messageAlert('error', this.message);
        return;
      }
      if(this.isSuccess) {
        this.messageAlert('success', this.message);
        this.$router.push({
        path: `/brands/displayPremiums/${this.id}`,
      });
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.setPremiumLimit();
    this.getBrandsNameById(this.id);
  },
  methods: {
  ...mapActions("brands", ["searchPremium", "insertPremiums","getPremiumLimit","getBrandsNameById"]),
  setPremiumLimit() {
    this.isPremiumSearch = true;
    let payload={
      id:this.$route.params.id,
    }
    this.getPremiumLimit(payload)
    .then((response) => {
      this.premiumSearchOptions = response.data.data.rows;
      this.isPremiumSearch = false;
    })
    .catch(() => {
      this.isPremiumSearch = false;
    });
  },onSubmit(event) {
		event.preventDefault();
		const data = this.form;
    const payload = {
      id: this.id,
      default_premium: data.default_premium.map(item => item.id),
    }
    this.insertPremiums(payload);
  },premiumFind(query) {
    if (!query) return;
    this.isPremiumSearch = true;
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      let payload={
        id:this.id,
        qry:query
      }
      this.searchPremium(payload)
      .then((response) => {
        this.premiumSearchOptions = response.data.data.rows;
        this.isPremiumSearch = false;
      }).catch(() => {
        this.isPremiumSearch = false;
      });
    }, 600);
  },messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
    });
  },reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  }
};
</script>
