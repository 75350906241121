var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "sm",
      "to": {
        path: '/brands/displayPremiums/' + this.id
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Back ")]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "bg-primary p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Create Premiums Brands(" + _vm._s(this.name) + ")")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('b-row', [_c('b-col', [_c('label', [_vm._v("Premium Offers")]), _c('multiselect', {
    attrs: {
      "placeholder": "Enter Premiums Offer",
      "open-direction": "bottom",
      "label": "name",
      "track-by": "name",
      "options": _vm.premiumSearchOptions,
      "loading": _vm.isPremiumSearch,
      "searchable": true,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true
    },
    on: {
      "search-change": _vm.premiumFind
    },
    model: {
      value: _vm.form.default_premium,
      callback: function ($$v) {
        _vm.$set(_vm.form, "default_premium", $$v);
      },
      expression: "form.default_premium"
    }
  })], 1)], 1), _c('b-row', [_c('label'), _c('div', {
    staticClass: "col-md-4"
  }, [_c('br'), _c('b-button', {
    staticClass: "btn btn-sm col-sm-auto",
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Create Premiums ")])], 1)])], 1)])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }